import { isEqual, sortBy } from "lodash-es";
import { flow, getEnv, IModelType, Instance, types } from "mobx-state-tree";

import {
  isOldEnoughToBeExcludedByRetirement,
} from "../../../components/common/ribbon/filter/ExcludeOldRetiredMembersCheckbox";
import { UsersApi } from "../../../services/api/UsersApi";
import { AppNotifier } from "../../../services/msg/AppNotifier";
import { compareLists } from "../../../utils/compareLists";
import { dateEq } from "../../../utils/date";
import { hasNoChangeReduce } from "../../../utils/model";
import { checkIfFilterTextMatches } from "../../../utils/searchwords";
import { getDI } from "../../common/getDI";
import { NendoDepts } from "../../depts/NendoDepts";
import { NendoRanks } from "../../ranks/NendoRanks";
import { AccountPageModelSymbol, AccountPageModelType } from "../AccountsPageModel";
import { eqInputAccountAbsence } from "./InputAccountAbsence";
import { InputAccountValue } from "./InputAccountValue";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types
  .model("InputAccount", {
    userId: types.identifier,

    orgValue: InputAccountValue,
    value: InputAccountValue,

    suggedstedPassword: types.optional(types.string, ""),
    persisted: types.boolean,
  })
  .views(self => {
    return {
      get hasChangesInNature() {
        return !self.persisted;
      },
      get errors() {
        return self.value.errors;
      },
    };
  })
  .views(self => {
    return {
      get userNameHasNoChange() {
        return !self.hasChangesInNature && self.orgValue.userName === self.value.userName;
      },
      get depCodeHasNoChange() {
        return !self.hasChangesInNature && self.orgValue.depCode === self.value.depCode;
      },
      get rankCodeHasNoChange() {
        return !self.hasChangesInNature && self.orgValue.rankCode === self.value.rankCode;
      },
      get mailAddressHasNoChange() {
        return !self.hasChangesInNature && self.orgValue.mailAddress === self.value.mailAddress;
      },
      get absenceHasNoChange() {
        return (
          !self.hasChangesInNature && compareLists(self.orgValue.absences, self.value.absences, eqInputAccountAbsence)
        );
      },
      get leaveDateHasNoChange() {
        return !self.hasChangesInNature && dateEq(self.orgValue.leaveDate, self.value.leaveDate);
      },
      get rolesHasNoChange() {
        return !self.hasChangesInNature && isEqual(sortBy(self.orgValue.roles), sortBy(self.value.roles));
      },
    };
  })
  .views(self => {
    const accountPageModel = (): AccountPageModelType => getEnv(self).get(AccountPageModelSymbol);
    function ranks(): typeof NendoRanks.Type {
      return accountPageModel().ranks!;
    }
    function depts(): typeof NendoDepts.Type {
      return accountPageModel().depts!;
    }

    return {
      get isOldEnoughExcludeByRetirement() {
        return isOldEnoughToBeExcludedByRetirement(self.orgValue.leaveDate);
      },
      get fixedPasswordIsUsed() {
        return accountPageModel().fixedPasswordIsUsed;
      },
      get hasNoChange() {
        if (self.hasChangesInNature) {
          return false;
        }
        return hasNoChangeReduce([
          self.userNameHasNoChange,
          self.depCodeHasNoChange,
          self.rankCodeHasNoChange,
          self.mailAddressHasNoChange,
          self.absenceHasNoChange,
          self.leaveDateHasNoChange,
          self.rolesHasNoChange,
        ]);
      },
      get rankName() {
        const rankCode = self.value.rankCode;
        if (!rankCode) {
          return "";
        }
        const foundRank = ranks().getRank(rankCode);
        if (!foundRank) {
          return "";
        }
        return foundRank.rankName;
      },
      get depName() {
        const depCode = self.value.depCode;
        if (!depCode) {
          return "";
        }
        const foundDep = depts().getDept(depCode);
        if (!foundDep) {
          return "";
        }
        return foundDep.name;
      },
      get passwordInitDisabled() {
        return !self.persisted;
      },
    };
  })
  .actions(self => {
    const accountPageModel = () => getEnv(self).get(AccountPageModelSymbol) as AccountPageModelType;
    const usersApi = () => getDI(self, UsersApi);
    const appNotifier = () => getDI(self, AppNotifier);

    const initPassword = flow(function*() {
      try {
        yield usersApi().setInitPassword(self.userId, self.suggedstedPassword);
        appNotifier().info({ message: `${self.value.userName} (${self.userId}) のパスワードを初期化しました。` });
      } catch {
        appNotifier().error({
          message: `${self.value.userName} (${self.userId}) のパスワードの初期化に失敗しました。`,
        });
      }
    });

    return {
      setPersisted(value: boolean) {
        self.persisted = value;
      },
      regenerateSuggestedPassword() {
        self.suggedstedPassword = accountPageModel().generatePassword();
      },
      initPassword,
    };
  })
  .views(self => {
    return {
      get searchedWords() {
        return [self.userId, self.rankName, self.depName, ...self.value.searchedWords, ...self.orgValue.searchedWords];
      },
    };
  })
  .views(self => {
    return {
      filterMatched(searchWords: string[], roleCodes: string[]) {
        if (!checkIfFilterTextMatches(self.searchedWords, searchWords)) {
          return false;
        }

        if (roleCodes.length === 0) {
          return true;
        }

        return roleCodes.every(roleCode => self.value.roles.some(it => roleCode === it));
      },
    };
  });

export type InputAccountType = typeof InputAccount.Type;

export const InputAccountSymbol = "InputAccount_Symbol";
export const InputAccount: InputAccountModelType = model;
type InputAccountInferredType = typeof model;
export interface InputAccountModelType extends InputAccountInferredType {}

type InputAccountIIf = Instance<typeof InputAccount>;
export interface InputAccountInstance extends InputAccountIIf {}
