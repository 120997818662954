import pathToRegexp from "path-to-regexp";

function parseFirstElem(path: string) {
  return RegExp("^/([^/]*)(/.*)?$").exec(path);
}

function parseYYYY(path: string) {
  const matchResult = /^\/(\d{4})$/.exec(path);

  if (!matchResult) {
    return {
      targetYear: null,
      others: "",
    };
  }

  const targetYear = +matchResult[1];
  const others = matchResult[4] || "";

  return {
    targetYear,
    others,
  };
}

function parseYYYYMMDD(path: string) {
  const matchResult = /^\/(\d{4})\/(\d{1,2})?(?:\/(\d{1,2})(\/.*)?)?(?:.*)$/.exec(path);

  if (!matchResult) {
    return {
      targetYear: null,
      targetMonth: null,
      targetDay: null,
      others: "",
    };
  }

  const targetYear = +matchResult[1];
  const targetMonth = +matchResult[2];
  const targetDay = matchResult[3] ? +matchResult[3] : null;
  const others = matchResult[4] || "";

  return {
    targetYear,
    targetMonth,
    targetDay,
    others,
  };
}

export const fromPaths = {
  global: (path: string) => {
    const matchResult = parseFirstElem(path);
    if (!matchResult) {
      return {
        menuItem: "",
        others: "",
      };
    }

    const menuItem = matchResult[1];
    const others = matchResult[2] || "";
    return {
      menuItem,
      others,
    };
  },
  /** 勤怠 */
  kintai: {
    ofUser: {
      index: (pathFragment: string) => {
        const matchResult = parseFirstElem(pathFragment);
        if (!matchResult) {
          return {
            targetUserId: undefined,
            others: "",
          };
        }

        const targetUserId = matchResult[1];
        const others = matchResult[2] || "";

        return {
          targetUserId,
          others,
        };
      },

      ofDate: {
        index: (pathFragment: string): KintaiOfDateReturnType => parseYYYYMMDD(pathFragment),
      },
    },
  },

  /** ダッシュボード */
  dashboard: {
    index: (pathFragment: string) => {
      return fromPaths.global(pathFragment);
    },

    statistics: {
      ofDate: {
        index: (pathFragment: string): KintaiOfDateReturnType => parseYYYYMMDD(pathFragment),
      },
    },

    kintai_sum: {
      search: (pathFragment: string) => {
        const result = pathToRegexp("/:year(\\d+)?/:depCode?").exec(pathFragment);

        if (result) {
          const [_, year, depCode] = result;
          const targetYear = +year;

          return {
            targetYear: year ? +year : null,
            targetDepCode: depCode ? depCode : null,
          };
        }
        return {
          targetYear: null,
          targetDepCode: null,
        };
      },
    },

    kintai_list: {
      search: (pathFragment: string) => {
        const result = pathToRegexp("/:mode(dept|reviewee|privileged|)/:year(\\d+)?/:month(\\d+)?/:depCode?").exec(
          pathFragment,
        );
        type TargetModeType = "dept" | "reviewee" | "privileged" | null;

        if (result) {
          const [_, targetMode, year, month, depCode] = result;
          const targetYear = +year;

          return {
            targetMode: (targetMode ? targetMode : null) as TargetModeType,
            targetYear: year ? +year : null,
            targetMonth: month ? +month : null,
            targetDepCode: depCode ? depCode : null,
          };
        }
        return {
          targetMode: null as TargetModeType,
          targetYear: null,
          targetMonth: null,
          targetDepCode: null,
        };
      },
    },

    interview_list: {
      ofYear: {
        index: (pathFragment: string) => {
          const { targetYear } = parseYYYY(pathFragment);
          return {
            targetYear,
          };
        },
      },
    },

    saburoku: {
      ofDate: {
        index: (pathFragment: string): KintaiOfDateReturnType => parseYYYYMMDD(pathFragment),
      },
    },

    export: {
      ofDate: {
        index: (pathFragment: string): KintaiOfDateReturnType => parseYYYYMMDD(pathFragment),
      },
    },
  },

  /** 管理 */
  admin: {
    index: (pathFragment: string) => {
      return fromPaths.global(pathFragment);
    },

    /** アカウント管理 */
    accounts: {
      index: (pathFragment: string) => {
        return;
      },
    },

    /** カレンダー管理 */
    calendar: {
      ofYear: {
        index: (pathFragment: string) => {
          const { targetYear } = parseYYYY(pathFragment);
          return {
            targetYear,
          };
        },
      },
    },

    /** ランク管理 */
    ranks: {
      ofYear: {
        index: (pathFragment: string) => {
          const { targetYear } = parseYYYY(pathFragment);
          return {
            targetYear,
          };
        },
      },
    },

    /** 部門勤怠管理責任者 */
    depts: {
      ofYear: {
        index: (pathFragment: string) => {
          const { targetYear } = parseYYYY(pathFragment);
          return {
            targetYear,
          };
        },
      },
    },

    /** S-Port */
    sport: {
      ofYear: {
        index: (pathFragment: string) => {
          const { targetYear } = parseYYYY(pathFragment);
          return {
            targetYear,
          };
        },
      },
    },

    /** 月報締め */
    pjClose: {
      ofYear: {
        index: (pathFragment: string) => {
          const { targetYear } = parseYYYY(pathFragment);
          return {
            targetYear,
          };
        },
      },
    },
  },

  /** 自分 */
  profile: {
    index: (pathFragment: string) => {
      return fromPaths.global(pathFragment);
    },

    interview: {
      index: (pathFragment: string) => {
        return {};
      },
    },
  },

  /** パスワードリセット */
  password_token_reset: {
    index: (completePath: string) => {
      const mat = completePath.match(/\/password_reset\?token=(.+)/);
      if (mat) {
        return mat[1];
      }
      return undefined;
    },
  },
};

export type KintaiOfDateReturnType =
  | {
      targetYear: null;
      targetMonth: null;
      targetDay: null;
      others: string;
    }
  | {
      targetYear: number;
      targetMonth: number;
      targetDay: null | number;
      others: string;
    };
