import { flow, getEnv, types } from "mobx-state-tree";

import { Login, LoginSymbol } from "./Login";

const model = types.optional(
  types
    .model("LoginFormModel", {
      userId: types.string,
      password: types.string,
      rememberLogin: types.boolean,
      inputLocked: types.optional(types.boolean, false),
    })
    .views(self => {
      const login = (): typeof Login.Type => getEnv(self).get(LoginSymbol);

      return {
        get showConsole() {
          return login().needsLogin;
        },
      };
    })
    .actions(self => {
      const login = (): typeof Login.Type => getEnv(self).get(LoginSymbol);

      return {
        setUserId(value: string) {
          self.userId = value;
        },
        setPassword(value: string) {
          self.password = value;
        },
        setRememberLogin(value: boolean) {
          self.rememberLogin = value;
        },
        onSubmitLogin: flow(function*() {
          try {
            self.inputLocked = true;
            yield login().login(self.userId, self.password, self.rememberLogin);
          } finally {
            self.inputLocked = false;
          }
        }),
      };
    }),
  {
    userId: "",
    password: "",
    rememberLogin: false,
  },
);
export const LoginFormModelSymbol = "Symbol_LoginForm";
export const LoginFormModel: LoginFormModelModelType = model;
type LoginFormModelInferredType = typeof model;
export interface LoginFormModelModelType extends LoginFormModelInferredType {}
