import { flow, getSnapshot, types } from "mobx-state-tree";

import { fromPaths } from "../routing/fromPaths";
import { paths } from "../routing/paths";
import { AppRouter } from "../services/AppRouter";
import { CronTasks } from "../services/CronTasks";
import { AppNotifier } from "../services/msg/AppNotifier";
import { sleep } from "../utils/async";
import { Accounts } from "./accounts/Accounts";
import { AccountsPageModel } from "./accounts/AccountsPageModel";
import { AdminPageModel } from "./admin/AdminPageModel";
import { AppConfirmQueue } from "./applications/AppConfirmQueue";
import { ApplicationsPageModel } from "./applications/ApplicationsPageModel";
import { GlobalWarnings } from "./applications/GlobalWarnings";
import { AppMode } from "./AppMode";
import { AppScreens } from "./AppScreens";
import { AppStatus } from "./AppStatus";
import { CalendarPageModel } from "./calendar/CalendarPageModel";
import { NendoCalendarsInput } from "./calendar/input/NendoCalendarsInput";
import { NendoCalendars } from "./calendar/NendoCalendars";
import { getDI } from "./common/getDI";
import { DashboardPageModel } from "./dashboard/DashboardPageModel";
import { Depts } from "./depts/Depts";
import { DeptsPageModel } from "./depts/DeptsPageModel";
import { ExportPageModel } from "./export/ExportPageModel";
import { InterviewListPageModel } from "./interview_list/InterviewListPageModel";
import { KintaiPageModel } from "./kintai/KintaiPageModel";
import { KintaiUsers } from "./kintai/KintaiUsers";
import { KintaiListPageModel } from "./kintai_list/KintaiListPageModel";
import { KintaiSumPageModel } from "./kintai_sum/KintaiSumPageModel";
import { Login } from "./login/Login";
import { LoginFormModel } from "./login/LoginFormModel";
import { PasswordChangeAppFormModel } from "./login/PasswordChangeAppFormModel";
import { PasswordChangeFormModel } from "./login/PasswordChangeFormModel";
import { PasswordTokenResetFormModel } from "./login/PasswordTokenResetFormModel";
import { NotificationPageModel } from "./notify/NotificationPageModel";
import { Notifications } from "./notify/Notifications";
import { PHPageModel } from "./ph/PHPageModel";
import { PjClosePageModel } from "./pjclose/PjClosePageModel";
import { Profile } from "./profile/Profile";
import { ProfileInterviewPageModel } from "./profile/ProfileInterviewPageModel";
import { ProfilePageModel } from "./profile/ProfilePageModel";
import { ProfileSettingsPageModel } from "./profile/ProfileSettingsPageModel";
import { RanksInput } from "./ranks/input/RanksInput";
import { RankPageModel } from "./ranks/RankPageModel";
import { Ranks } from "./ranks/Ranks";
import { Roles } from "./roles/Roles";
import { SaburokuPageModel } from "./saburoku/SaburokuPageModel";
import { SPortAdminPageModel } from "./sport/SPortAdminPageModel";
import { StasticsPageModel } from "./statistics/StasticsPageModel";
import { TransDayPageModel } from "./trans_day/TransDayPageModel";
import { Users } from "./users/Users";

const model = types.optional(
  types
    .model("AppState", {
      // アプリ全体系
      mode: AppMode,
      state: AppStatus,
      initStatusText: types.optional(types.string, ""),
      screenMode: AppScreens,
      isInApiServerFault: types.optional(types.boolean, false),
      apiServerFaultMsg: types.optional(types.maybe(types.string), undefined),
      appConfirm: AppConfirmQueue,
      globalWarnings: GlobalWarnings,

      // マスタデータ・リポジトリ
      accounts: Accounts,
      depts: Depts,
      nendoCalendars: NendoCalendars,
      ranks: Ranks,
      roles: Roles,

      // ログイン
      login: Login,
      loginFormModel: LoginFormModel,
      passwordChangeFormModel: PasswordChangeFormModel,

      // パスワードトークンリセット系
      passwordChangeAppFormModel: PasswordChangeAppFormModel,
      passwordTokenResetFormModel: PasswordTokenResetFormModel,

      // プロファイル
      profile: Profile,
      profilePageModel: ProfilePageModel,
      profileSettingsPageModel: ProfileSettingsPageModel,
      notifications: Notifications,
      notificationPageModel: NotificationPageModel,
      profileInterviewPageModel: ProfileInterviewPageModel,

      // 勤怠ページ
      kintaiPageModel: KintaiPageModel,
      kintaiUsers: KintaiUsers,
      users: Users,

      // ダッシュボード系
      dashboardPageModel: DashboardPageModel,
      statisticsPageModel: StasticsPageModel,
      kintaiListPageModel: KintaiListPageModel,
      kintaiSumPageModel: KintaiSumPageModel,
      transDayPageModel: TransDayPageModel,
      interviewListPageModel: InterviewListPageModel,
      saburokuPageModel: SaburokuPageModel,
      applicationsPageModel: ApplicationsPageModel,
      exportPageModel: ExportPageModel,

      // 管理系
      adminPageModel: AdminPageModel,
      accountPageModel: AccountsPageModel,
      phPageModel: PHPageModel,
      deptsPageModel: DeptsPageModel,
      calendarPageModel: CalendarPageModel,
      nendoCalendarsInput: NendoCalendarsInput,
      rankPageModel: RankPageModel,
      ranksInput: RanksInput,
      pjClosePageModel: PjClosePageModel,
      sPortAdminPageModel: SPortAdminPageModel,
    })
    .volatile(self => ({
      recoverFromApiServerFaultCallback: [] as Array<() => void>,
    }))
    .views(self => {
      return {
        get showsProfileInterviewProfilePage() {
          return self.profileInterviewPageModel.needsInterviewAnswer;
        },
      };
    })
    .actions(self => {
      function logInitStatus(msg: string) {
        console.log(msg);
        self.initStatusText = msg;
      }

      const appNotifier = () => getDI(self, AppNotifier);
      const appRouter = () => getDI(self, AppRouter);
      const cronTasks = () => getDI(self, CronTasks);

      const initAppData = flow(function*() {
        logInitStatus("ログイン状態の復帰中");
        yield self.login.ensureLogin();

        logInitStatus("プロファイルデータのロード中");
        yield self.profile.loadProfile();

        logInitStatus("通知データのロード中");
        yield self.notifications.load();
        // cronTasks().init();
        // cronTasks().registerTask(10 * 60 * 1000, () => {
        //   return self.notifications.load();
        // });

        // 特に待たずにサイト全体警告系の初期化を開始させる
        self.globalWarnings.init();

        // 特に待たずに個人ダッシュボードの内容を初期化させる
        self.statisticsPageModel.appInit();
      });

      const dispose = () => {
        console.log("App will be shutdown.");
        cronTasks().dispose();
        appRouter().dispose();
      };

      const init: () => Promise<unknown> = flow(function*() {
        if (self.state !== "started") {
          yield initAppLaunch();
        }

        // ここで各ページの初期化処理が始まる
        appRouter().start();
      });

      const initAppLaunch = flow(function*() {
        logInitStatus("アプリが起動しました");

        // パスワード初期化モードの場合は初期化UIを出し、その後再起動する
        self.passwordTokenResetFormModel.acceptUrl(location.pathname + decodeURIComponent(location.search));
        if (self.passwordTokenResetFormModel.isInPasswordTokenResetMode) {
          logInitStatus("パスワードトークンリセットモードを起動しています");
          self.state = "started";
          self.mode = "token_reset";
          try {
            yield self.passwordTokenResetFormModel.startResetProcedure();
          } finally {
            logInitStatus("パスワードトークンリセットモードが完了しました。再起動します。");
            window.webkintaiReset();
          }
          return;
        }

        logInitStatus("アプリを初期化しています");
        self.mode = "kintai";
        self.state = "init";
        try {
          logInitStatus("初期データのロード中");
          yield initAppData();
        } catch (exception) {
          const restartSec = 10;
          appNotifier().error({ message: "アプリ起動中にエラーが発生しました。", exception });
          logInitStatus(`アプリ起動中にエラーが発生しました。${restartSec}秒後に再起動します。`);
          yield sleep(restartSec * 1000);
          return yield init();
        }

        logInitStatus("初期化完了");
        self.state = "started";
      });

      return {
        init,
        dispose,
        navigate(path: string) {
          appRouter().navigate(path);
        },
        onNavigate(path: string) {
          // Routing.
          const { menuItem, others } = fromPaths.global(path);
          console.log("GlobalMenu", menuItem);
          switch (menuItem) {
            case "kintai":
              self.screenMode = "kintai";
              self.kintaiPageModel.route(others);
              break;
            case "dashboard":
              self.screenMode = "dashboard";
              self.dashboardPageModel.route(others);
              break;
            case "admin":
              self.screenMode = "admin";
              self.adminPageModel.route(others);
              break;
            case "about":
              self.screenMode = "help";
              break;
            case "profile":
              self.screenMode = "profile";
              self.profilePageModel.route(others);
              break;
            default:
              if (self.profile.isExective) {
                // 役員はダッシュボードが初期表示
                appRouter().navigate(paths.dashboard.index());
                break;
              }

              // 通常社員は勤怠表が初期表示
              appRouter().navigate(paths.kintai.index());
              break;
          }
        },
        recoverFromApiServerFailureRecovery(error: any) {
          self.apiServerFaultMsg = JSON.stringify(error);
          return new Promise(done => {
            self.recoverFromApiServerFaultCallback.push(done);
            self.isInApiServerFault = true;
          });
        },
        retryApiRequest() {
          self.isInApiServerFault = false;
          self.recoverFromApiServerFaultCallback.forEach(it => it());
        },
      };
    })
    .actions(self => {
      return {
        afterCreate() {
          Object.assign(window, {
            dump() {
              console.log(getSnapshot(self));
            },
            webkintaiReset() {
              self.dispose();
              location.reload();
            },
          });
        },
      };
    }),
  {
    mode: "none",
    state: "init",
    screenMode: "kintai",
  },
);

export const AppStateSymbol = "AppState_Symbol";
export const AppState: AppStateModelType = model;
type AppStateInferredType = typeof model;
export interface AppStateModelType extends AppStateInferredType {}
